import type { UseFetchOptions } from '#app';
// import type { NitroFetchOptions } from '@nuxt';
export default <T>(url: string, options: UseFetchOptions<T> = {}): Promise<T> =>
  new Promise((resolve, reject) => {
    useAsyncUserToken().then((token) => {
      const allOptions = Object.assign(options, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // @ts-ignore
      $fetch<T>(url, allOptions)
        .then((data) => {
          // @ts-ignore
          resolve(data as T);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
